import { Dialog } from "../../Dialog";
import { Link } from "../../Link";
import { PartnerLogo } from "../../../ui/PartnerLogo/PartnerLogo";

export const agentPhone = "1-800-782-8332";

type StateFarmHelpDialogProps = {
  onDismiss: () => void;
};

export const StateFarmHelpDialog = ({
  onDismiss,
}: StateFarmHelpDialogProps) => (
  <Dialog
    label="State Farm Help"
    role="dialog"
    size="medium"
    onDismiss={onDismiss}
  >
    <PartnerLogo partner={"state-farm"} />
    <p>
      For policy-related assistance, please contact your State Farm agent at{" "}
      <Link phoneNumber={agentPhone} />.
    </p>
  </Dialog>
);
