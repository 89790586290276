import {
  Customer,
  formatPhoneNumber,
  getDisplayName,
  MutableCustomer,
  phoneNumbers,
} from "shared";
import { Address } from "./Address/Address";
import {
  useIsSso,
  useIsStateFarmPartitioned,
  useUrls,
} from "../../app/Providers/AppContext";
import { useIsHdcMember } from "../../app/Providers/DriversClubContext";
import { useState } from "react";
import { useDialog } from "../Dialog";
import { useBeforeunload } from "react-beforeunload";
import { mt } from "../../utils/tracking";
import { logSettingsDialog } from "./utils/logSettingsDialog";
import styles from "./Settings.module.scss";
import { Link } from "../Link";
import { CommunicationPreferences } from "./CommunicationPreferences/CommunicationPreferences";
import { SettingsDialog } from "./SettingsDialog/SettingsDialog";
import { StateFarmHelpDialog } from "./StateFarmHelpDialog/StateFarmHelpDialog";
import { Alert } from "../Alert";
import { Card } from "../Card";
import { SourceType, Status } from "./SettingsDialog/SettingsDialog.types";
import { useInsurance } from "../Insurance/useInsurance";

export type SettingsProps = {
  initialCustomer: Customer;
};

export const Settings = ({ initialCustomer }: SettingsProps) => {
  const urls = useUrls();
  const { isHdcMember } = useIsHdcMember();
  useInsurance(); // fetch policies so we know whether user is policyholder

  const [dialogStatus, setDialogStatus] = useState<Status>("ok");
  const [customer, setCustomer] = useState<Customer>(initialCustomer);

  const editDialog = useDialog();
  const stateFarmDialog = useDialog();

  const [sourceType, setSourceType] = useState<SourceType | null>(null);

  const isSso = useIsSso();
  const hasStateFarmPartition = useIsStateFarmPartitioned();
  const isStateFarmSsoLogin = hasStateFarmPartition && isSso;

  // Notify the user of unsaved changes if they close the window before the save is complete
  useBeforeunload((event: any) => {
    if (dialogStatus === "pending") {
      event.preventDefault();
      return "Save in progress";
    }
  });

  const openEditDialog = (type: SourceType) => {
    logSettingsDialog(type);
    setSourceType(type);
    editDialog.open();
  };

  const closeEditDialog = () => {
    mt.trackPopupClose();
    editDialog.close();
  };

  const openStateFarmDialog = () => {
    mt.trackPopup("state farm");
    stateFarmDialog.open();
  };

  const closeStateFarmDialog = () => {
    mt.trackPopupClose();
    stateFarmDialog.close();
  };

  async function onSubmit(mutableCustomer: MutableCustomer) {
    setDialogStatus("pending");

    const oldCustomer = customer;

    // Since mutable customer only has some of the necessary properties, blend with existing full customer
    const newCustomer = { ...customer, ...mutableCustomer };

    // Optimistically update the app's UI to show the new record, even though it hasn't been saved yet (to avoid drawing attention to the slow call)
    setCustomer(newCustomer);

    // Close the dialog immediately when the form is submitted.
    // This avoids "locking" the user into the modal while the save is in progress.
    closeEditDialog();

    try {
      const resp = await fetch(`${window.location.origin}/api/customer`, {
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(mutableCustomer),
      });
      if (!resp.ok) throw resp;
      setDialogStatus("ok");
      return true;
    } catch {
      setCustomer(oldCustomer);
      setDialogStatus("mutateError");
      return false;
    }
  }

  const displayName = getDisplayName(customer);
  const { line1, line2, city, state, postalCode, phone } = customer;

  function renderDemographicInfoForm() {
    const showHelpDialogOnEdit = hasStateFarmPartition;
    return (
      <>
        <dl>
          <div className={styles.rowWrapperName}>
            <dt className={styles.descriptionText}>Name</dt>
            <dd>{displayName}</dd>
          </div>

          {!isStateFarmSsoLogin && (
            <div className={styles.rowWrapper}>
              <dt className={styles.descriptionText}>Login settings</dt>
              <dd>
                <Link
                  className={styles.editLink}
                  ariaLabel="Manage login settings"
                  href={urls.account.login}
                >
                  Manage settings
                </Link>
              </dd>
            </div>
          )}
          <div className={styles.rowWrapper}>
            <dt className={styles.descriptionText}>Mailing address</dt>
            <dd className={styles.infoWithLink}>
              <div className={`with-button ${styles.withButton}`}>
                <Address
                  line1={line1}
                  line2={line2}
                  city={city}
                  state={state}
                  postalCode={postalCode}
                  fallback={<span>Not provided</span>}
                />
                <button
                  aria-label="Edit contact information"
                  className={styles.buttonLink}
                  onClick={
                    showHelpDialogOnEdit
                      ? () => openStateFarmDialog()
                      : () => openEditDialog("mailing address")
                  }
                >
                  Edit
                </button>
              </div>
            </dd>
          </div>

          <div className={styles.rowWrapper}>
            <dt id="phone-number-header" className={styles.descriptionText}>
              Phone number
            </dt>
            <dd className={styles.infoWithLink}>
              <div className={`with-button ${styles.withButton}`}>
                {phone ? (
                  <span aria-labelledby="phone-number-header">
                    {formatPhoneNumber(phone)}
                  </span>
                ) : (
                  <span>Not provided</span>
                )}
                <button
                  aria-label="Edit contact information"
                  className={styles.buttonLink}
                  onClick={
                    showHelpDialogOnEdit
                      ? () => openStateFarmDialog()
                      : () => openEditDialog("phone number")
                  }
                >
                  Edit
                </button>
              </div>
            </dd>
          </div>

          <CommunicationPreferences styles={styles} />
        </dl>
        {editDialog.isOpen && sourceType && (
          <SettingsDialog
            sourceType={sourceType}
            status={dialogStatus}
            customer={customer}
            onSubmit={onSubmit}
            onCloseDialog={closeEditDialog}
          />
        )}

        {stateFarmDialog.isOpen && (
          <StateFarmHelpDialog onDismiss={closeStateFarmDialog} />
        )}

        {dialogStatus === "pending" && (
          <Alert
            ariaLabel="Saving contact information"
            className={styles.alert}
            message="Saving..."
            level="info"
          />
        )}

        {dialogStatus === "mutateError" && (
          <Alert
            className={styles.alert}
            onClose={() => setDialogStatus("ok")}
            closeButtonAriaLabel="Close error message"
            showIcon
            message={
              <div className={styles.message}>
                <div>
                  Sorry, we are unable to update your information at this time.
                  Please try again or call{" "}
                  <Link phoneNumber={phoneNumbers.auto} /> for assistance.
                </div>
              </div>
            }
            level="danger"
          />
        )}
      </>
    );
  }

  function renderCommunicationPreferences() {
    return (
      <dl>
        <CommunicationPreferences styles={styles} />
      </dl>
    );
  }

  return (
    <div className="container container_center">
      <main id="main">
        <Card width="full" className={styles.container}>
          {/* State Farm partner via SSO login, without HDC cannot modify demographic settings */}
          {isStateFarmSsoLogin && !isHdcMember
            ? renderCommunicationPreferences()
            : renderDemographicInfoForm()}
        </Card>
      </main>
    </div>
  );
};
