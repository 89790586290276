import { FormEvent, useState, useRef, ChangeEvent } from "react";
import { ReactComponent as LeftArrowIcon } from "@hagerty/design-system/src/assets/icons/16/16-arrow-left.svg";
import { Customer, MutableCustomer } from "shared";
import {
  DialogContent,
  DialogOverlay,
  DialogButtonGroup,
  DialogButton,
} from "../../Dialog";
import { decodePhoneNumber } from "../../../utils/decodePhoneNumber";
import {
  SettingsDialogProps,
  TouchedFields,
  ValidationErrors,
} from "./SettingsDialog.types";
import { logSettingsDialog } from "../utils/logSettingsDialog";
import {
  phoneRegExp,
  usPostalCodeRegExp,
  caPostalCodeRegExp,
  caPostalCodeWithSpaceRegExp,
} from "../utils/regExp";
import { SettingsForm } from "./SettingsForm/SettingsForm";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog/UnsavedChangesDialog";

const formFieldNames = [
  "firstName",
  "middleName",
  "lastName",
  "line1",
  "line2",
  "city",
  "state",
  "postalCode",
  "phone",
] as const;

const areCustomersTheSame = (cust1: MutableCustomer, cust2: MutableCustomer) =>
  formFieldNames.every((key) => cust1[key] === cust2[key]);

export const SettingsDialog = ({
  sourceType,
  status,
  customer: initialCustomer,
  onCloseDialog,
  onSubmit,
}: SettingsDialogProps) => {
  const topOfFormRef = useRef<HTMLDivElement>(null);
  const formattedInitialCustomer = {
    ...initialCustomer,
    phone: decodePhoneNumber(initialCustomer.phone),
    postalCode: initialCustomer.postalCode.toUpperCase(),
  };
  const [customer, setCustomer] = useState<Customer>(formattedInitialCustomer);
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const pending = status === "pending";

  const checkIfDirtyThenClose = () => {
    const isDirty = !areCustomersTheSame(formattedInitialCustomer, customer);
    if (isDirty) {
      setShowUnsavedChangesDialog(true);
      return;
    }
    onCloseDialog();
  };

  function getValidationErrors() {
    const errors: ValidationErrors = {};
    if ((submitted || touchedFields.firstName) && !customer.firstName)
      errors.firstName = "Please enter your first name";

    if ((submitted || touchedFields.lastName) && !customer.lastName)
      errors.lastName = "Please enter your last name";

    const postalRegex =
      customer.country === "USA" ? usPostalCodeRegExp : caPostalCodeRegExp;

    if (
      (submitted || touchedFields.postalCode) &&
      !RegExp(postalRegex).test(customer.postalCode)
    ) {
      errors.postalCode =
        "Please provide a valid postal code based on the address country";
    }

    if (
      (submitted || touchedFields.phone) &&
      !RegExp(phoneRegExp).test(customer.phone)
    ) {
      errors.phone = "Please provide a valid 10-digit phone number";
    }

    const errorMessages = Object.values(errors);
    if (errorMessages.length > 0) {
      logSettingsDialog(sourceType, errorMessages);
    }

    return errors;
  }

  function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setCustomer({
      ...customer,
      [event.target.id]: event.target.value,
    } as Customer);
  }

  function handleCanadaPostalCode() {
    if (customer.country === "USA") return;

    const postalCode = customer.postalCode.trim();
    const hasSpace = RegExp(caPostalCodeWithSpaceRegExp).test(postalCode);

    if (hasSpace) return;

    const firstHalf = postalCode.substring(0, 3);
    const secondHalf = postalCode.substring(3);
    customer.postalCode = `${firstHalf} ${secondHalf}`;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSubmitted(true);
    const errors = getValidationErrors();
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      handleCanadaPostalCode();
      // Send customer without country to onSubmit since the user can't change their country.
      const { country, ...customerWithoutCountry } = customer;
      onSubmit(customerWithoutCountry);
    } else {
      topOfFormRef.current?.scrollIntoView();
    }
  }

  function onBlur({
    target,
  }: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) {
    setTouchedFields((cur) => ({
      ...cur,
      [target.id]: true,
    }));
  }

  const validationErrors = getValidationErrors();

  return (
    <>
      <DialogOverlay size="medium" onDismiss={checkIfDirtyThenClose}>
        <form onSubmit={handleSubmit}>
          <DialogContent
            title="Contact information"
            role="dialog"
            onDismiss={checkIfDirtyThenClose}
            showBusy={pending}
            closeBtnLabel="Close contact information dialog"
            footer={
              <DialogButtonGroup>
                <DialogButton
                  variant="outline"
                  aria-label="Cancel update and close this window"
                  onClick={checkIfDirtyThenClose}
                  disabled={pending}
                  type="button"
                >
                  <LeftArrowIcon
                    className="button_primary__icon icon"
                    aria-hidden="true"
                  />
                  Back
                </DialogButton>
                <DialogButton
                  type="submit"
                  aria-label="Save contact information"
                  disabled={pending}
                >
                  {pending ? "Saving..." : "Save"}
                </DialogButton>
              </DialogButtonGroup>
            }
          >
            <div ref={topOfFormRef}>
              <SettingsForm
                customer={customer}
                setCustomer={setCustomer}
                validationErrors={validationErrors}
                pending={pending}
                onChange={onChange}
                onBlur={onBlur}
                submitted={submitted}
              />
            </div>
          </DialogContent>
        </form>
      </DialogOverlay>
      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={() => {
            setShowUnsavedChangesDialog(false);
            logSettingsDialog("leave without saving");
            onCloseDialog();
          }}
          onCancel={() => {
            setShowUnsavedChangesDialog(false);
            logSettingsDialog(sourceType);
          }}
        />
      )}
    </>
  );
};
