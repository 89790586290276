import { Customer } from "shared";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotFound } from "../not-found";
import { AccountProfile } from "../ui/AccountProfile";
import { PolicyDetailsWithSuspense } from "../ui/PolicyDetails";
import { Settings } from "../ui/Settings";

type AppRouteTypes = {
  customer: Customer;
};

export const AppRoutes = ({ customer }: AppRouteTypes) => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AccountProfile customer={customer} />} />
      <Route
        path="/settings"
        element={<Settings initialCustomer={customer} />}
      />
      <Route
        path="/policy/:policyNumber"
        element={<PolicyDetailsWithSuspense />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
