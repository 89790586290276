import { useState } from "react";
import { TagAction } from "../../../types/Tag.types";
import {
  useContactId,
  useEnvironment,
  useUrls,
} from "../../../app/Providers/AppContext";
import { Link } from "../../Link";
import {
  DialogContent,
  DialogOverlay,
  DialogButtonGroup,
  DialogButton,
} from "../../Dialog";

const label = "Communication preferences";
const ariaLabel = "Manage communication preferences";
const linkText = "Manage preferences";

const managePreferencesTagAction: TagAction = {
  actionName: "Manage preferences",
  actionType: "link",
};

type CommunicationPreferencesProps = {
  styles: Record<string, string>;
};

export function CommunicationPreferences({
  styles,
}: CommunicationPreferencesProps) {
  const urls = useUrls();
  const [showNonProdDialog, setShowNonProdDialog] = useState(false);
  const contactId = useContactId();
  const environment = useEnvironment();

  // There's only a prod url, so we show an explanatory dialog in non-prod.
  function renderLinkOrDialogButton() {
    if (environment === "production") {
      return (
        <Link
          className={styles.editLink}
          ariaLabel={ariaLabel}
          tagAction={managePreferencesTagAction}
          href={urls.account.editPreferences(contactId)}
        >
          {linkText}
        </Link>
      );
    }

    return (
      <button
        aria-label={ariaLabel}
        className={styles.buttonLink}
        onClick={() => setShowNonProdDialog(true)}
      >
        {linkText}
      </button>
    );
  }

  return (
    <div className={styles.rowWrapperCommunication}>
      <dt className={styles.descriptionText}>{label}</dt>
      <dd>{renderLinkOrDialogButton()}</dd>

      {showNonProdDialog && (
        <NonProdDialog onCloseDialog={() => setShowNonProdDialog(false)} />
      )}
    </div>
  );
}

type NonProdDialogProps = {
  onCloseDialog: () => void;
};

const NonProdDialog = ({ onCloseDialog }: NonProdDialogProps) => {
  return (
    <DialogOverlay onDismiss={onCloseDialog}>
      <DialogContent
        role="dialog"
        title="Cannot update preferences in non-prod"
        showCloseButton={"no"}
        onDismiss={onCloseDialog}
        footer={
          <DialogButtonGroup>
            <DialogButton aria-label="close dialog" onClick={onCloseDialog}>
              Close
            </DialogButton>
          </DialogButtonGroup>
        }
      >
        <p>
          Changing preferences in lower environments updates production data.
          This will be available when Salesforce Marketing Cloud is available in
          lower environents.
        </p>
      </DialogContent>
    </DialogOverlay>
  );
};
