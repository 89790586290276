import { mt } from "../../../utils/tracking";
import { SourceType } from "../SettingsDialog/SettingsDialog.types";

export const logSettingsDialog = (
  sourceType: SourceType,
  errorMessages?: string[]
): void => {
  const modalType = `edit contact information : ${sourceType}`;
  mt.trackPopup(modalType);

  if (errorMessages?.length) {
    errorMessages.forEach((error) =>
      mt.trackError({
        errorType: "settings",
        errorDetails: error,
      })
    );
  }
};
